<template>
  <div>
    <dashboard-page-title @addEvent="$router.push({name: 'addOffer'})">العروض</dashboard-page-title>
    <main-table :fields="fields" :list_url="'merchant/flash-sales'" paginationName="عروض"></main-table>
  </div>
</template>
<script>
import { core } from '@/config/pluginInit'
export default {
  components: {},
  mounted () {
    core.index()
  },
  data () {
    return {
      fields: [
        // '#',
        { label: 'الاسم', key: 'name', class: 'text-right' },
        { label: 'النوع', key: 'type', class: 'text-right' },
        { label: 'تاريخ البدء', key: 'start_date', class: 'text-right' },
        { label: 'تاريخ الانتهاء', key: 'end_date', class: 'text-right' },
        { label: 'الحالة', key: 'status', type: 'status', class: 'text-right' },
        { label: 'تاريخ الانشاء', key: 'created_at', class: 'text-right' },
        {
          label: this.$t('products.control'),
          key: 'actions',
          class: 'text-right',
          type: 'actions',
          actions: [
            {
              text: 'edit',
              icon: 'ri-ball-pen-fill',
              color: 'info',
              ifNavigate: true,
              routePath: 'edit-offer/:id'
            },
            {
              text: 'Delete',
              icon: 'ri-delete-bin-line',
              color: 'danger',
              url: 'merchant/flash-sales',
              titleHeader: 'العرض',
              question: 'هل متأكد أنك تريد مسح هذا العرض',
              textContent: 'name',
              textDeleteButton: 'بالتأكيد, مسح',
              textCancelButton: 'الغاء',
              showAlert: true
            }
          ]
        }
      ]
    }
  },
  methods: {
    addMerchant () {
    }
  }
}
</script>
